/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
import { RecurlySubscription } from '@/graphql/chat';
import { getABTestAnalyticData } from '@/lib/abTesting';
import { getUtmCookieData } from '@/lib/utm_cookie';

export function logCouponRemoved(couponCode: string, discount?: number) {
  const abTestData = getABTestAnalyticData();
  const utmCookieData = getUtmCookieData();

  window.__DW_Next_Bridge?.Analytics.logEvent('Coupon Removed', {
    ...abTestData,
    ...utmCookieData,
    // order_id: Order/transaction ID, if applicable,
    coupon_id: couponCode,
    // TODO: Mike - update tracking protocols in segment
    // coupon_name: getValues('coupon_code'),
    discount,
  });
}

export function logSubscriptionUpgraded(subscription: RecurlySubscription, newSubscription: RecurlySubscription) {
  const abTestData = getABTestAnalyticData();
  const utmCookieData = getUtmCookieData();

  window.__DW_Next_Bridge?.Analytics.logEvent(`Subscription Upgraded`, {
    anonymousId: '6402d5f8a55457a14fe27f49468110ce',
    context: {
      userAgent: navigator.userAgent,
      library: {
        name: 'unknown',
        version: 'unknown',
      },
    },
    event: 'Subscription Upgraded',
    integrations: {},
    messageId: 'api-2DH1Zvr9qYWhbnczBvfVGz9zSli',
    originalTimestamp: new Date().toJSON(),
    properties: {
      ...abTestData,
      ...utmCookieData,
      new_plan_billing_amount: newSubscription.unit_amount_in_cents / 100,
      new_plan_code: newSubscription.plan_code,
      new_plan_name: newSubscription.plan_name,
      original_plan_billing_amount: subscription.unit_amount_in_cents ? subscription.unit_amount_in_cents / 100 : 0,
      original_plan_code: subscription.plan_code,
      original_plan_name: subscription.plan_name,
    },
    receivedAt: new Date().toJSON(),
    timestamp: new Date().toJSON(),
    type: 'track',
  });
}
