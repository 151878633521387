import { css, Theme } from '@emotion/core';
import styled, { CreateStyled } from '@emotion/styled';

export const MoviePageMainContent = (styled as CreateStyled<Theme>).div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;

    ${theme.mq.desktop} {
      padding-top: 70px;
    }
  `,
);
