/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';

import { imgixLoader } from '@delicious-simplicity/next-image-imgix-loader';
import Image from 'next/image';
import NextLink from 'next/link';

import { Heading } from '@/components/design-system';
import { Clip } from '@/graphql/app';

interface IClipTeaserStackedProps {
  clip: Clip;
}

const ClipTeaserStacked: React.FC<IClipTeaserStackedProps> = ({ clip }) => {
  return (
    <article>
      <NextLink href={`/clips/${clip.slug}`}>
        <a>
          <div
            css={{
              position: 'relative',
              flexShrink: 0,
              alignSelf: 'flex-start',
              lineHeight: 0,
              marginBottom: 8,
            }}
          >
            {!!clip.image && (
              <Image
                alt={clip.name || ''}
                css={{ borderRadius: '15px', border: ' 0.5px solid rgba(11, 11, 17, 0.11) !important' }}
                height={294}
                loader={(props) => imgixLoader(props, { fit: 'crop', ar: '16:9' })}
                src={clip.image}
                width={522}
              />
            )}
          </div>
          <div css={{ marginTop: 16 }}>
            <Heading
              as='h3'
              css={(theme) => ({
                marginTop: 8,
                color: '#949494',
                fontFamily: 'Libre Franklin',
                textAlign: 'center',
                fontSize: '14px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              })}
              variant='300'
            >
              {clip.name}
            </Heading>
          </div>
        </a>
      </NextLink>
    </article>
  );
};

export default ClipTeaserStacked;
