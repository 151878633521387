import React, { FC } from 'react';

import { resolvePlanName } from '@/components/account/subscription/utils';
import { Text } from '@/components/design-system';

interface PaywallUnlockMessageProps {
  requiredAccess?: string[];
  needsToUpgrade: boolean;
}

export const PaywallUnlockMessage: FC<PaywallUnlockMessageProps> = ({ requiredAccess, needsToUpgrade }) => {
  const minimumRequirementPlanCode = requiredAccess?.[0];
  const requiredPlanName = resolvePlanName(minimumRequirementPlanCode);

  if (!requiredPlanName) return <Text as='h1'>Unlock access with a membership today.</Text>;

  if (minimumRequirementPlanCode === 'ALL_ACCESS' && needsToUpgrade) {
    return (
      <Text as='h1'>
        This episode is exclusive to
        <br />
        All Access members.
      </Text>
    );
  }

  return (
    <Text as='h1'>
      Unlock access with a <span style={{ textTransform: 'uppercase', whiteSpace: 'nowrap' }}>DW+</span> membership{' '}
      today.
    </Text>
  );
};
