/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
import { useRouter } from 'next/router';
import useSWR from 'swr';

import { useAuth0 } from '@/context/auth';
import { GetVideoBySlugQuery } from '@/graphql/app';
import { appApi } from '@/request/middleware';
import { AppApi } from '@/request/types';

import { VideoQueryKeys } from '../types';

export const useVideo = (fallbackData?: GetVideoBySlugQuery, slugStatic?: string) => {
  const { isAuthenticated } = useAuth0();
  const router = useRouter();

  return useSWR(
    isAuthenticated ? [VideoQueryKeys.Video, slugStatic || router.query.slug] : null,
    (_, slug, appApi: AppApi) => appApi.getVideoBySlug({ slug }),
    {
      fallbackData,
      revalidateOnFocus: false,
      revalidateOnMount: true,
      use: [appApi],
    },
  );
};
