import React, { FC } from 'react';

import { Button, Text } from '@/components/design-system';

import { PaywallUnlockMessage } from './PaywallUnlockMessage';
import { canAccess } from './utils';

interface VideoPaywallBoxProps {
  isAuthenticated?: boolean;
  userPlanCode?: string;
  requiredAccess?: string[];
  imageUrl?: string;
  onPaywallCtaClick?: () => void;
  onLoginClick?: () => void;
}

export const VideoPaywallBox: FC<VideoPaywallBoxProps> = ({
  isAuthenticated,
  userPlanCode,
  requiredAccess,
  imageUrl,
  onPaywallCtaClick,
  onLoginClick,
}) => {
  const needsToJoin = !isAuthenticated;
  const needsToUpgrade = !needsToJoin && (!userPlanCode || !canAccess(userPlanCode, requiredAccess));

  return (
    <div
      style={{
        position: 'relative',
        paddingBottom: '56.25%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        backgroundImage: imageUrl ? `url(${imageUrl})` : 'none',
        backgroundSize: 'cover',
      }}
    >
      <div className='join-to-watch'>
        <PaywallUnlockMessage needsToUpgrade={needsToUpgrade} requiredAccess={requiredAccess} />

        <div>
          <Button onClick={onPaywallCtaClick} size='large' variant='primary'>
            {needsToJoin && 'Join to Watch'}
            {needsToUpgrade && 'Upgrade Now'}
          </Button>
        </div>

        {!isAuthenticated && (
          <Text>
            Already a member?{' '}
            <Text onClick={onLoginClick} style={{ cursor: 'pointer', textDecoration: 'underline' }}>
              Login
            </Text>
          </Text>
        )}
      </div>
    </div>
  );
};
