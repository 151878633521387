import { getPlanDetails } from '@/utils/helpers';

import { planUpgradeOptionsMap } from '../planMetadata';
import { SubscriptionPlan } from '../types';

export function canUpgradeToPlan(currentPlanCode: string, newPlanCode: string) {
  const upgradeOptions = planUpgradeOptionsMap?.get(currentPlanCode);
  return !!upgradeOptions?.includes(newPlanCode);
}

export function resolveAvailablePlanCodes(currentPlanCode: string) {
  return ['reader', 'insider', 'insider_plus', 'all_access'].filter(
    (planCode) => planCode === currentPlanCode || canUpgradeToPlan(currentPlanCode, planCode),
  );
}

export function resolvePlanName(planCode?: string) {
  if (!planCode) return undefined;
  const planDetails = getPlanDetails(planCode?.toLowerCase());
  return planDetails?.title;
}

export function resolveCommonPlanPerks(
  baselinePlanCode: string,
  newPlanCode: string,
): SubscriptionPlan['perks'] | undefined {
  const baselinePlan = getPlanDetails(baselinePlanCode);
  if (baselinePlanCode === newPlanCode) return baselinePlan?.perks;

  const newPlan = getPlanDetails(newPlanCode);
  if (!baselinePlan?.perks?.length || !newPlan?.perks?.length) return [];

  const newPlanPerkSet = new Set<string>(newPlan?.perks);
  return baselinePlan?.perks.filter((perk) => newPlanPerkSet?.has(perk));
}

export function resolveUniquePlanPerks(
  baselinePlanCode: string,
  newPlanCode: string,
): SubscriptionPlan['perks'] | undefined {
  const baselinePlan = getPlanDetails(baselinePlanCode);
  const newPlan = getPlanDetails(newPlanCode);

  if (!newPlan?.perks?.length) return [];
  if (!baselinePlan?.perks?.length) return newPlan?.perks;

  const baselinePlanPerkSet = new Set<string>(baselinePlan?.perks);
  return newPlan?.perks?.filter((perk) => !baselinePlanPerkSet?.has(perk));
}
